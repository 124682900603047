/**
 * 联合登录模块接口列表
 */

import axios from '../request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const dingtalk = {
    // 钉钉登录
    login(params) {
        return axios.post('/madmin/dingtalk/login', qs.stringify(params))
    },
}

export default dingtalk
