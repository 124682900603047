/**
 * 订单分配模块接口
 */

import axios from '../request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const allocation = {
    // 查询
    search(params) {
        return axios.post('/madmin/allocation/search', qs.stringify(params))
    },
	// 详情
	detail(id) {
	    return axios.get('/madmin/allocation/detail?id=' + id)
	},
    // 分配配送员
    allocation(params) {
        return axios.post('/madmin/allocation/allocation', qs.stringify(params))
    },
    // 分单调度
    dispatch(params) {
        return axios.post('/madmin/allocation/dispatch', qs.stringify(params))
    }
}

export default allocation
