/**
 * 试吃模块接口列表
 */

import axios from '../request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const foretaste = {
    // 查询
    search(params) {
        return axios.post('/madmin/foretaste/search', qs.stringify(params))
    },
    // 新增|修改
    input(params) {
        return axios.post('/madmin/foretaste/input', qs.stringify(params))
    },
    // 审核
    audit(params) {
        return axios.post('/madmin/foretaste/audit', qs.stringify(params))
    },
    // 取消
    cancel(params) {
        return axios.post('/madmin/foretaste/cancel', qs.stringify(params))
    },
    // 发起审批提醒
    sendAudit(params) {
        return axios.post('/madmin/foretaste/sendAudit', qs.stringify(params))
    },
    // 加载配送日期
    loadSendDate(params) {
        return axios.post('/madmin/foretaste/loadSendDate', qs.stringify(params))
    },
    // 加载配送时间
    loadSendTime(params) {
        return axios.post('/madmin/foretaste/loadSendTime', qs.stringify(params))
    },
    // 加载配送时间
    detail(billNo) {
        return axios.get('/madmin/foretaste/detail?billNo=' + billNo)
    },
}

export default foretaste
