/**
 * 用户模块接口列表
 */

import axios from '../request/http' // 导入http中创建的axios实例

const region = {
    // 加载全部
    loadAll() {
        return axios.get('/madmin/region/loadAll')
    },
    // 加载省
    loadProvince() {
        return axios.get('/madmin/region/loadProvince')
    },
    // 加载城市
    loadCity(provinceId) {
        return axios.get('/madmin/region/loadCity?provinceId=' + provinceId)
    },
    // 加载地区
    loadCounty(cityId) {
        return axios.get('/madmin/region/loadCounty?cityId=' + cityId)
    }
}

export default region
