/**
 * 节点模块接口列表
 */

import axios from '../request/http'
import qs from "qs"; // 导入http中创建的axios实例

const node = {
    // 加载节点
    load() {
        return axios.get('/madmin/node/load')
    },
    // 加载权限
    loadAuth() {
        return axios.get('/madmin/node/loadAuth')
    },
    // 加载收藏夹
    loadFavorite() {
        return axios.get('/madmin/node/loadFavorite')
    },
    // 搜索节点
    search(keyword) {
        return axios.get('/madmin/node/search?keyword=' + keyword)
    },
    // 添加节点到收藏夹
    addFavorite(params) {
        return axios.post('/madmin/node/addFavorite', qs.stringify(params))
    },
    // 从收藏夹删除节点
    removeFavorite(params) {
        return axios.post('/madmin/node/removeFavorite', qs.stringify(params))
    },
}

export default node
