/**
 * 用户模块接口列表
 */

import axios from '../request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const customer = {
    // 查询
    search(params) {
        return axios.get('/madmin/customer/pool/search?' + qs.stringify(params))
    },
    // 新增|修改
    input(params) {
        return axios.post('/madmin/customer/pool/input', qs.stringify(params))
    },
    // 删除
    delete(params) {
        return axios.post('/madmin/customer/pool/delete', qs.stringify(params))
    }
}

export default customer
