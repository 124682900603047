/**
 * api接口的统一出口
 */

// 导出接口
import user from './user' // 用户模块接口
import node from './node' // 菜单模块接口
import delivery from './delivery' // 配送模块接口
import wechat from './wechat' // 微信模块接口
import dingtalk from './dingtalk' // 联合登录模块接口
import company from './company' // 大客户模块接口
import region from './region' // 大客户模块接口
import address from './address' // 地址模块接口
import goods from "./goods" // 商品模块接口
import foretaste from "./foretaste" // 试吃模块接口
import apply from "./apply" // 申领模块接口
import card from "./card" // 申领模块接口
import message from "./message" // 消息模块接口
import stock from "./stock" // 库存模块接口
import customer from "./customer" // 库存模块接口
import allocation from "./allocation" // 订单分配模块接口

// 导出接口
export default {
    user,
    node,
    delivery,
    wechat,
    dingtalk,
    company,
    region,
    address,
    goods,
    foretaste,
    apply,
    card,
    message,
    stock,
    customer,
	allocation
}
