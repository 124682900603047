/**
 * 微信模块接口列表
 */

import axios from '../request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const wechat = {
    // 签名
    sign(params) {
        return axios.post('/madmin/wechat/sign', qs.stringify(params))
    },
}

export default wechat
