/**
 * 商品模块接口列表
 */

import axios from '../request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const goods = {
    // 查询
    search(params) {
        return axios.get('/madmin/goods/search?' + qs.stringify(params))
    },
    // 查询
    searchByName(params) {
        return axios.get('/madmin/goods/searchByName?' + qs.stringify(params))
    },
    // 详情
    detail(params) {
        return axios.get('/madmin/goods/detail?' + qs.stringify(params))
    },
    // 一件复制
    copy(params) {
        return axios.post('/madmin/goods/copy', qs.stringify(params))
    }
}

export default goods
