/**
 * 试吃模块接口列表
 */

import axios from '../request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const message = {
    // 加载
    load(params) {
        return axios.post('/madmin/message/load', qs.stringify(params))
    },
    // 标记已读
    read(params) {
        return axios.post('/madmin/message/read', qs.stringify(params))
    },
    // 获取消息节点消息
    detail(id) {
        return axios.get('/madmin/message/detail?id=' + id)
    },
}

export default message
