import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'
import localstore from 'store'

Vue.use(Router)

const router = new Router({
    mode: 'history', // 去掉地址栏#号
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {
                title: 'MCAKE管理中心',
                requireAuth: true,
            },
            component: resolve => require(['@/components/home'], resolve)
        },
        {
            path: '/login',
            name: 'login',
            meta: {
                title: 'MCAKE管理中心',
            },
            component: resolve => require(['@/components/login'], resolve)
        }
    ]
})

router.beforeEach((to, from, next) => {
    store.state.user.token = localstore.get('token') || ''
    if (to.meta.title) { // 判断是否有标题 该操作可以再监听路由时候处理 watch:{'$route'(to,from){xxx操作}}
        document.title = to.meta.title
    }
    if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
        if (store.state.user.token) { // 通过vuex state获取当前的token是否存
            // 已登录
            next()
        } else {
            // 未登录
            next({
                path: '/login',
                query: {redirect: to.fullPath} // 将跳转的路由path作为参数，登录成功后跳转到该路由
            })
        }
    } else {
        next()
    }
})

/*router.afterEach((to, from) => {
    // 加载完成
})*/

export default router
