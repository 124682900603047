/**
 * 试吃模块接口列表
 */

import axios from '../request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const apply = {
    // 查询
    search(params) {
        return axios.post('/madmin/apply/search', qs.stringify(params))
    },
    // 新增|修改
    detail(id) {
        return axios.get('/madmin/apply/detail?id=' + id)
    },
    // 新增|修改
    input(params) {
        return axios.post('/madmin/apply/input', qs.stringify(params))
    },
    // 审核
    audit(params) {
        return axios.post('/madmin/apply/audit', qs.stringify(params))
    },
    // 反审
    rollback(params) {
        return axios.post('/madmin/apply/rollback', qs.stringify(params))
    },
    // 取消
    delete(params) {
        return axios.post('/madmin/apply/delete', qs.stringify(params))
    },
    // 取消
    send(params) {
        return axios.post('/madmin/apply/send', qs.stringify(params))
    },
}

export default apply
