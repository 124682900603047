/**
 * 试吃模块接口列表
 */

import axios from '../request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const card = {
    // 查询
    search(params) {
        return axios.post('/madmin/card/search', qs.stringify(params))
    },
    // 加载类型
    loadType() {
        return axios.get('/madmin/card/loadType')
    },
    // 加载面值
    loadFace() {
        return axios.get('/madmin/card/loadFace')
    },
    // 加载规格
    loadSpec() {
        return axios.get('/madmin/card/loadSpec')
    },
    // 加载回款方式
    loadReturnMethod() {
        return axios.get('/madmin/card/loadReturnMethod')
    },
    // 加载回款期限
    loadReturnDays() {
        return axios.get('/madmin/card/loadReturnDays')
    },
}

export default card
