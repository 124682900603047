/**
 * 商品模块接口列表
 */

import axios from '../request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const stock = {
    // 新增
    add(params) {
        return axios.post('/madmin/stock/add', qs.stringify(params))
    },
    // 删除
    del(params) {
        return axios.post('/madmin/stock/del', qs.stringify(params))
    },
    // 新增
    edit(params) {
        return axios.post('/madmin/stock/edit', qs.stringify(params))
    },
    // 查询
    search(params) {
        return axios.get('/madmin/stock/search?' + qs.stringify(params))
    },
    // 增加库存
    inc(params) {
        return axios.post('/madmin/stock/inc', qs.stringify(params))
    },
    // 减少库存
    dec(params) {
        return axios.post('/madmin/stock/dec', qs.stringify(params))
    },
}

export default stock
