// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';

// 引入vant
import Vant from 'vant';
import 'vant/lib/index.css';
import {Notify, Toast, Dialog} from 'vant';

Vue.use(Vant);

import App from './App';
import router from './router';
import Api from './api';
import store from './store';

// 引入dingtalk-jsapi
import * as dd from 'dingtalk-jsapi';
// 引入微信jssdk
import wx from 'weixin-js-sdk';

Vue.config.productionTip = false;
Vue.prototype.$api = Api;

Vue.prototype.$vant = Vant;
Vue.prototype.$notify = Notify;
Vue.prototype.$toast = Toast;
Vue.prototype.$dialog = Dialog;
Vue.prototype.$dd = dd;
Vue.prototype.$wx = wx;
Vue.prototype.$store = store;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
