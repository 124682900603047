/**
 * 微信模块接口列表
 */

import axios from '../request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const company = {
    // 查询
    search(params) {
        return axios.post('/madmin/company/search', qs.stringify(params))
    },
    // 新增修改
    input(params) {
        return axios.post('/madmin/company/input', qs.stringify(params))
    },
    // 设置状态
    change(params) {
        return axios.post('/madmin/company/change', qs.stringify(params))
    },
    // 删除
    delete(params) {
        return axios.post('/madmin/company/delete', qs.stringify(params))
    },
    // 客户转移
    transfer(params) {
        return axios.post('/madmin/company/transfer', qs.stringify(params))
    },
    // 详情
    detail(id) {
        return axios.get('/madmin/company/detail?id=' + id)
    },
    // 加载公司人数
    loadEmployees() {
        return axios.get('/madmin/company/loadEmployees')
    },
    // 加载公司性质
    loadIndustry() {
        return axios.get('/madmin/company/loadIndustry')
    },
    // 加载公司行业
    loadNature() {
        return axios.get('/madmin/company/loadNature')
    },
    // 套餐管理接口
    package: {
        // 查询
        search(params) {
            return axios.post('/madmin/company/package/search', qs.stringify(params))
        },
        // 套餐详情
        detail(id) {
            return axios.get('/madmin/company/package/detail?id=' + id)
        },
        // 新增修改套餐
        input(params) {
            return axios.post('/madmin/company/package/input', qs.stringify(params))
        },
        // 删除
        delete(params) {
            return axios.post('/madmin/company/package/delete', qs.stringify(params))
        },
        // 发布券号
        publish(params) {
            return axios.post('/madmin/company/package/publish', qs.stringify(params))
        }
    },
    // 套餐兑换接口
    exchange: {
        // 查询套餐详情
        search(params) {
            return axios.post('/madmin/company/exchange/search', qs.stringify(params))
        },
        // 兑换套餐
        detail(id) {
            return axios.get('/madmin/company/exchange/detail?id=' + id)
        },
        // 兑换套餐
        exchange(params) {
            return axios.post('/madmin/company/exchange/exchange', qs.stringify(params))
        }
    }
}

export default company
