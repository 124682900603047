/**
 * 地址模块接口列表
 */

import axios from '../request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const address = {
    // 查询
    search(params) {
        return axios.post('/madmin/address/search', qs.stringify(params))
    },
    // 新增
    add(params) {
        return axios.post('/madmin/address/add', qs.stringify(params))
    },
    // 修改
    edit(params) {
        return axios.post('/madmin/address/edit', qs.stringify(params))
    },
    // 删除
    remove(params) {
        return axios.post('/madmin/address/remove', qs.stringify(params))
    },
    // 加载配送站
    loadSite(cityId) {
        return axios.get('/madmin/address/loadSite?cityId=' + cityId)
    }
}

export default address
