<template>
    <div id="app">
        <div class="container">
            <router-view/>
        </div>
        <div id="popup-app">

        </div>
    </div>
</template>

<script>
    export default {
        name: 'App',
        data() {
            return {}
        }
    }
</script>

<style>
    * {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    input, textarea {
        -webkit-user-select: auto;
    }
    body {
        margin: 0;
        padding: 0;
        background-color: #fff;
    }
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }
    .van-tabbar{
        background-color: #fafbff;
    }
    .van-tabbar-item--active{
        color: #108de9;
    }
    .van-search__action{
        color: #108de9;
        border-radius: 10px;
    }
    .van-grid-item__icon{
        font-size: 30px;
        color: #108de9;
    }
    .van-grid-item__text{
        color: #333333;
    }
    .van-popup__close-icon{
        color: #108de9;
    }
</style>
