import Vue from 'vue'
import Vuex from 'vuex'
import LocalStore from 'store'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        root: {
            nodes: []
        },
        user: {
            token: LocalStore.get('token') || '',
            info: LocalStore.get('userinfo') || '',
            auth: LocalStore.get('userauth') || [],
            apps: [],
            messageCount: 0
        },
        wxConfig: false
    },
    getters: {},
    mutations: {
        changeNetwork() {
            this.$toast('你的网络走丢了，请稍后再试')
        },
        changeLogin(state, data) {
            state.user.token = data.token;
            state.user.info = data.info;
            LocalStore.set('token', data.token);
            LocalStore.set('userinfo', data.info);
        },
        changeAuth(state, data) {
            state.user.auth = data;
            LocalStore.set('userauth', data);
        },
        changeUserApps(state, apps) {
            state.user.apps = apps
        },
        addUserApps(state, apps) {
            state.user.apps.push(apps)
        },
        changeUserInfo(state, info) {
            state.user.info = info;
            LocalStore.set('userinfo', info)
        },
        changeRootNodes(state, nodes) {
            state.root.nodes = nodes
        },
        changeWxConfig(state, wxConfig) {
            state.root.wxConfig = wxConfig
        },
        changeUserMessage(state, messageCount) {
            state.user.messageCount = messageCount
        },
        decUserMessage(state) {
            state.user.messageCount -= 1
        },
    },
    actions: {}
});

export default store
