/**
 * 配送模块接口列表
 */

import axios from '../request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const delivery = {
    // 加载汇总信息
    loadSummary(params) {
        return axios.post('/madmin/delivery/loadSummary', qs.stringify(params))
    },
    // 下车扫描
    putdown(params) {
        return axios.post('/madmin/delivery/putdown', qs.stringify(params))
    },
    // 出仓扫描
    putout(params) {
        return axios.post('/madmin/delivery/putout', qs.stringify(params))
    },
}

export default delivery
