/**
 * 用户模块接口列表
 */

import axios from '../request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const user = {
    // 登录
    login(params) {
        return axios.post('/madmin/user/login', qs.stringify(params))
    },
    // 退出
    logout() {
        return axios.get('/madmin/user/logout')
    },
    // 用户信息
    info() {
        return axios.get('/madmin/user/info')
    },
    // 设置密码
    setPassword(params) {
        return axios.post('/madmin/user/setPassword', qs.stringify(params))
    },
    // 设置个人信息
    setInfo(params) {
        return axios.post('/madmin/user/setInfo', qs.stringify(params))
    },
    // 加载城市
    loadCity() {
        return axios.get('/madmin/user/loadCity')
    },
    // 加载配送站
    loadSite(cityId) {
        return axios.get('/madmin/user/loadSite?cityId=' + cityId)
    },
	// 加载当前城市所有配送站
	loadSiteAll(cityId) {
	    return axios.get('/madmin/user/loadSite?cityId=' + cityId + '&type=all')
	},
    // 加载配送员
    loadCourier(siteId) {
        return axios.get('/madmin/user/loadCourier?siteId=' + siteId)
    },
	// 加载小区
	loadArea(siteId) {
	    return axios.get('/madmin/user/loadArea?siteId=' + siteId)
	},
    // 加载部门
    loadDept() {
        return axios.get('/madmin/user/loadDept')
    },
    // 设置个人信息
    loadUserByDept(deptId) {
        return axios.get('/madmin/user/loadUserByDept?deptId=' + deptId)
    },
    // 设置个人信息
    loadLeader(userId) {
        return axios.get('/madmin/user/loadLeader?userId=' + userId)
    }
}

export default user
